import FormValidator from '@yaireo/validator'
export class formValidator {
  constructor(form){
    this.lang = document.documentElement.lang
    this.form = form;
    this.formID =  this.form.getAttribute('id');
    this.form.fields = this.form.querySelectorAll('.field-item');
    this.form.inputs = this.form.querySelectorAll('input, select, textarea');
    Array.from(this.form.fields).forEach((field, i) => {
      field.classList.add('field')
    })
    Array.from(this.form.inputs).forEach((input, i) => {
      //console.log(input.classList)
      if(input.classList.contains("wpcf7-validates-as-required")){
        //console.log("required")
        input.setAttribute("required", "required");
      }
    })
    this.form.setAttribute("novalidate","")
    var validatorClasses = {
      field : 'field-item', // class for each input wrapper
      alert : 'alert', // call on the alert tooltip
      bad   : 'wpcf7-not-valid-tip'    // classes for bad input
  };
  let emptyFieldText = "";
  let emailIncorrectText = "";
  switch (this.lang) {
    case "fr-FR" :
      emptyFieldText = 'Ce champ est obligatoire.';
      emailIncorrectText = 'Adresse mail invalide.'
      break;
    case "es-ES" :
      emptyFieldText = 'Este campo es requerido.';
      emailIncorrectText = 'Dirección de correo electrónico es inválida.'
      break;
    case "en-GB" :
      emptyFieldText = 'This field is required.';
      emailIncorrectText = 'Email address is invalid.'
      break;
  }
    var validator = new FormValidator({
      texts : {
        email: emailIncorrectText,
        empty: emptyFieldText,
      }
    });
    this.form.onsubmit = function(e){
        var validatorResult = validator.checkAll(this); // "this" reffers to the currently submitetd form element
        if(validatorResult.valid == true){
          this.classList.add("valid")
          this.classList.remove("invalid")
        }else{
          this.classList.remove("valid")
        }
        return !!validatorResult.valid;
    };
    this.form.addEventListener('blur', function(e){
        console.log('blur')
        validator.checkField(e.target)
        //console.log( validator.checkField(e.target))
        if(validator.checkField(e.target).valid == false){
          this.classList.remove("valid")
        }
    }, true);
    
    this.form.addEventListener('input', function(e){
        console.log(e.target.getAttribute('type')!== "email")
        if(e.target.getAttribute('type')!== "email"){
          validator.checkField(e.target);
        
        
          if(validator.checkField(e.target).valid == false){
            this.classList.remove("valid")
          }
        }
    }, true);
    
    this.form.addEventListener('change', function(e){
        console.log('change')
        validator.checkField(e.target)
        if(validator.checkField(e.target).valid == false){
          this.classList.remove("valid")
        }
    }, true);

    
  }
}