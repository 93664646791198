/**
 * SASS
 */
import '../sass/frontend.scss';

/**
 * JavaScript
 */
import "core-js/stable";
import "regenerator-runtime/runtime";
import 'element-closest-polyfill';
import "objectFitPolyfill";
import "./utils/dataset";
import "./utils/object-assign"; 
import "./utils/prepend";
import "./utils/node-list-foreach";
import "./utils/picturefill";
import "./utils/picturefillbugff";
import "./utils/rAF";
import "./utils/svg-path-polyfill.min";

//import { writeCookie } from './utils/shortcuts';
import barba from '@barba/core';
import barbaPrefetch from '@barba/prefetch';
import {ScrollAnimations} from "./animations-scroll.js";
import { gsap } from "gsap";
import { CustomEase } from "gsap/CustomEase";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import {Menu} from "./menu.js";
import { formsInit } from './form';
import SplitType from 'split-type'

//import {loadScript} from './utils/shortcuts';
//import { doc } from 'prettier';

if(document.querySelector('#error-page')){
  document.body.classList.remove('waiting');
  gsap.set('body',{maxWidth: 'none'});
}
//barba.use(barbaPrefetch);
//
//const BackgroundCheck = require('./background-check.js');
let FontFaceObserver = require('fontfaceobserver/fontfaceobserver');
require('intersection-observer');
gsap.registerPlugin(ScrollTrigger);
gsap.registerPlugin(CustomEase);
barba.use(barbaPrefetch);
const imagesLoaded = require('imagesloaded');
//
/**
 * Add here your JavasScript code
 */
function isTouchDevice() {
  return (('ontouchstart' in window) ||
     (navigator.maxTouchPoints > 0) ||
     (navigator.msMaxTouchPoints > 0));
}
//markup()
//console.log(isTouchDevice());
const fontA = new FontFaceObserver('Chatelet');
//const fontB = new FontFaceObserver('Reckless');
//const fontB = new FontFaceObserver('FreightBig Pro');
Promise.all([fontA.load(null, 1000)/*, fontB.load(null, 1000)*/]).then( () => {
  
  const scrollAnimations = new ScrollAnimations();
  ScrollTrigger.refresh(true);
  
  document.body.classList.remove('waiting')
}).catch(function(err) {
  const scrollAnimations = new ScrollAnimations();
  ScrollTrigger.refresh(true);
  
  document.body.classList.remove('waiting')
  console.log('Some critical font are not available:', err);
});


function checkforVideo(video) {
  const videoTl = gsap.timeline({paused:true});
  videoTl.fromTo(video,
    {
      opacity: 0 
    },
    {
      opacity: 1,
      duration: .5,
      clearProps: "opacity"
    })
    //Every 500ms, check if the video element has loaded
    var b = setInterval(()=>{
        if(video.readyState >= 3){
            //This block of code is triggered when the video is loaded

            //your code goes here

            //stop checking every half second
            clearInterval(b);
            videoTl.play();

        }                   
    },500);
}
const initScripts = () => {
  Array.from(document.querySelectorAll('.video-sound-btn')).forEach((el)=>{
    const video = el.parentNode.parentNode;
    console.log()
    video.querySelector('video').onvolumechange = function() {
      //alert("The volume has been changed!");
  };
    if(video){
      el.addEventListener('click', (e)=>{
        console.log()
        video.classList.toggle('muted');

        if(video.classList.contains('muted')){
          video.querySelector('video').volume= 0
          video.querySelector('video').muted = true;
          console.log('off')
        }else{
          video.querySelector('video').muted = false;
          video.querySelector('video').volume = 1 
          console.log('on')
        }
        e.preventDefault();
        e.stopPropagation();
        e.stopImmediatePropagation()
      })
    }
    
  })


  Array.from(document.querySelectorAll('[data-barba="container"] a')).forEach((el)=>{
    //console.log(el)
    if(el.classList.contains('open-popup-video')) return
    if(el.getAttribute('href')){
      if(el.getAttribute('href').indexOf("#") === 0 && el.getAttribute('href') !== "#"){
        
        el.addEventListener('click', (e)=>{
          e.preventDefault()
          if(document.querySelector(el.getAttribute('href'))){
            let offset = document.querySelector('.site-header') ? document.querySelector('.site-header').offsetHeight :  0;
            offset += document.querySelector('#wpadminbar') ? document.querySelector('#wpadminbar').offsetHeight : 0;
            offset += 30;
            gsap.to(window, {duration: 1, scrollTo: {y:el.getAttribute('href'), offsetY:()=>  offset}, ease: "power2.inOut"});
          }
          
        })
        //
      }
    }
      
  })
  const menu = new Menu('.menu-toggle', '.site-menu-main', '.site-header', '.search-opener', '.btn-search-close');
  
  objectFitPolyfill();
  picturefill();


  /*FORMS*/
  formsInit();
  //////

  Array.from(document.querySelectorAll('.pll-switcher-select')).forEach((select)=>{
    select.addEventListener('change', (e)=>{
      location.href = e.currentTarget.value;
    })
  })

  ///ACCORDION///
  const accordions_nested = document.querySelectorAll('.accordion-nested');
  if(accordions_nested.length > 0) {
    
    let accordionsArray = [];
    import( /* webpackChunkName: "accordion" */ './accordion.js').then(({default: Accordion}) => {
      accordions_nested.forEach((accordion) => { 
        const closeOthers = accordion.dataset.closeOthers ? (accordion.dataset.closeOthers === "true" ? true : false ) : false;
        const closeAll = accordion.dataset.closeAll ? (accordion.dataset.closeAll === "true" ? true : false ) : false;
        return accordionsArray.push(new Accordion(accordion, closeOthers, closeAll, false, true))
      
      });
    })
  }
  const accordions = document.querySelectorAll('.accordion:not(.accordion-nested)');
  if(accordions.length > 0) {
    let accordionsArray = [];
   
    import( /* webpackChunkName: "accordion" */ './accordion.js').then(({default: Accordion}) => {
      accordions.forEach((accordion,i) => { 
        
        const closeOthers = accordion.dataset.closeOthers ? (accordion.dataset.closeOthers === "true" ? true : false ) : false;
        const closeAll = accordion.dataset.closeAll ? (accordion.dataset.closeAll === "true" ? true : false ) : false;
        return accordionsArray.push(new Accordion(accordion, closeOthers, closeAll, false, true))
      
      });
    })
  }
  
  ///SLIDER///
  const sliders = document.querySelectorAll('.slider-container');
  if(sliders.length > 0){
    const slidersArray = [];
    import( /* webpackChunkName: "slider" */ './slider.js').then(({default: Slider}) => {
      sliders.forEach((slider) => slidersArray.push(new Slider(slider)));
    });
  }
  //FILTER
  const filters = document.querySelectorAll('.filter');
  
  if(filters.length > 0){
    const filterArray = [];
    import( /* webpackChunkName: "filter" */ './filter.js').then(({default: Filter}) => {
      filters.forEach((filter) => filterArray.push(new Filter(filter)));
    });
  }
  

  //BLOC LEARN MORE/LESS
  Array.from(document.querySelectorAll('.lines-limit')).forEach((el)=>{
    const linesContainer = el.querySelector('.lines-inner');
    const linesWrapper = el.querySelector('.lines-wrapper');
    
    const lines  = new SplitType(linesContainer, { types: 'line', tagName: 'span' });
    const limit = el.dataset.linesLimit ? parseInt(el.dataset.linesLimit) : 5;
    //console.log('ELLEME', limit)
    const btn = el.querySelectorAll('.lines-opener');
    const setSize = ()=>{
      if(linesContainer.scrollHeight - 1 === linesContainer.querySelector('.line').offsetHeight * limit){
        gsap.set(btn, {display:'none'});
      }else{
        gsap.set(btn, {display:''});
      }
      if(!el.classList.contains('lines-limit--open')){
        //alert(linesContainer.querySelector('.line').offsetHeight * limit)
        gsap.to(linesWrapper,{height:()=> linesContainer.querySelector('.line').offsetHeight * limit, overwrite: true})
      }else {
        gsap.to(linesWrapper,{height:()=> linesContainer.scrollHeight , overwrite: true})
      }
    }
    setSize()
    Array.from(btn).forEach((b)=>{
      b.addEventListener('click', (e)=>{
        e.preventDefault;
        el.classList.toggle('lines-limit--open');
        setSize()
        
        
      })
    })
    let timeOut = null;
    const resizeObserver = new ResizeObserver(
      ()=>{
        timeOut = setTimeout(() => {
          clearTimeout(timeOut);
          lines.revert();
          lines.split();
          if(linesContainer.children.length <=  limit ){
            gsap.set(btn, {display:'none'});
          }else{
            gsap.set(btn, {display:''});
          }
        }, 100)
      }
    )
    resizeObserver.observe(el.parentNode)

  })

  //POPUP VIDEO
  Array.from(document.querySelectorAll('.open-popup-video')).forEach((link)=>{
    link.addEventListener('click', (e)=>{
      document.querySelector(e.currentTarget.getAttribute('href')).classList.add('popup-video--open');
      if(!e.currentTarget.closest('.bloc-videos-item').querySelector('iframe') && !e.currentTarget.closest('.bloc-videos-item').querySelector('.main-video')){
        const dataContainer = document.createElement('div');
        dataContainer.innerHTML = e.currentTarget.dataset.src;
        console.log(e.currentTarget, e.currentTarget.dataset.src);
        e.currentTarget.closest('.bloc-videos-item').querySelector('.popup-video >.container').appendChild(dataContainer);
        dataContainer.replaceWith(...dataContainer.childNodes)
      }
      e.preventDefault()
    })
  })
  Array.from(document.querySelectorAll('.close-video, .close-video-overlay')).forEach((link)=>{
    link.addEventListener('click', (e)=>{
      e.currentTarget.closest('.popup-video').classList.remove('popup-video--open');
      if(e.currentTarget.closest('.popup-video').querySelector('.iframe-wrapper')){
        e.currentTarget.closest('.popup-video').querySelector('.iframe-wrapper').parentNode.removeChild(e.currentTarget.closest('.popup-video').querySelector('iframe'));

      }else if(e.currentTarget.closest('.popup-video').querySelector('.main-video')){
        e.currentTarget.closest('.popup-video').querySelector('.main-video').pause();
      }
      
      e.preventDefault()
    })
  })

  
  if(document.body.querySelector('.archive-filter-form')){
    import( /* webpackChunkName: "archives-filter-barba" */ './archives-filter-barba').then(({default: FilterPosts}) => {
      Array.from(document.body.querySelectorAll('.archive-filter-form')).forEach((el,i)=>{
        const filterPosts = new FilterPosts(el);
      })
     
      
    });
  }
  

}
initScripts();
Array.from(document.querySelectorAll('video')).forEach((video)=>{
  checkforVideo(video);
})
let vh = window.innerHeight * 0.01;
// Then we set the value in the --vh custom property to the root of the document
document.documentElement.style.setProperty('--vh', `${vh}px`);

window.addEventListener('resize', () => {
  // We execute the same script as before
  let vh = window.innerHeight * 0.01;
  document.documentElement.style.setProperty('--vh', `${vh}px`);

});

window.addEventListener('hashchange',() =>{
  ScrollTrigger.refresh()
});

Array.from(document.querySelectorAll("#wpadminbar")).forEach((el)=>{
  el.setAttribute('data-barba-prevent', 'all');
})

const logoTL = gsap.timeline({paused: true, onComplete: ()=>{

}});

const logoLoaderPaths = document.querySelectorAll('#logo-loader-stroke path');
console.log(logoLoaderPaths.length, 'LENGTH')
logoLoaderPaths.forEach((el)=>{
    const length  = el.getTotalLength();
    gsap.set(el, {strokeDasharray:length});
    gsap.set(el, {strokeDashoffset:length});
  })

//const customEase = CustomEase.create("custom", "M0,0 C0.164,0.848 0.304,0.976 1,1 ");
const customEase = CustomEase.create("custom", "M0,0 C0.166,0.532 0.276,0.982 1,1 ");
//logoTL.fromTo('.loader-inner-logo',{scale: .95}, {scale:1, duration:.7, ease: "power3.out", repeat: -1, yoyo: true}, 'start' );
const loaderTL = gsap.timeline({paused: true});//{repeat:-1}

loaderTL.addLabel("start");
loaderTL.set('.loader',{xPercent: -100}, 'start');


loaderTL.fromTo('.loader-background',{opacity: 0}, {opacity: 0.4, duration:.5,delay:0}, 'start' );
loaderTL.fromTo('.loader-inner',{yPercent: 100}, {yPercent: 0, duration:.7,delay:.2, ease: customEase}, 'start' );

loaderTL.fromTo('.loader-inner-logo',{yPercent: -100}, {yPercent: 0, duration:.7,delay:.2,  ease: customEase}, 'start' );

loaderTL.to(logoLoaderPaths, {strokeDashoffset:0, opacity:1, duration: 1.2, delay:.5,ease: 'slow (.1, .7, true)'}, 'start');
//loaderTL.to('#logo-loader-fill', { opacity:1, duration: 1, delay:.5, delay:.7, ease: "power2.out"}, 'start');


loaderTL.add(play);
loaderTL.add(pause);
loaderTL.addLabel("end");
loaderTL.add(pauseLogo);
loaderTL.set('.loader-background',{opacity: 0,  onComplete:()=>{ /*return markup()*/}}, 'end' );
loaderTL.to('.loader-inner svg',{scale: 1.2, yPercent: -10, opacity: 0,  duration:.7, ease: "power2.inOut"}, 'end' );
loaderTL.to('.loader-inner',{ opacity: 0,  duration:.7, ease: "power2.inOut", onComplete:()=> {
  
  return new ScrollAnimations(document.querySelector('.new-barba'))
}
}, 'end' );



loaderTL.set('.loader',{xPercent: 0});
function pause(){
  console.log("pause")
  loaderTL.pause();
}
function pauseLogo(){
  console.log("pause2")
  logoTL.pause();
}
function play(){
  console.log("play")
  logoTL.play();
}
let scrollX = 0
let scrollY = 0

// function markup(){
//   document.querySelectorAll('#footer-menu li, .footer-copyright p').forEach((el, i)=>{
//     gsap.set(el,{transition:'none'});
//     gsap.set(el,{transitionDelay:0});
//     gsap.set(el,{opacity:0});
//     el.setAttribute('data-trigger-start', 'top bottom');
//     el.setAttribute('data-trigger', '#footer');
//     el.classList.add('gs_reveal');
//     el.classList.add('gs_reveal_fromBottom');
//     el.offsetHeight
//     el.removeAttribute('style');
//     el.setAttribute('data-delay', 1+i);

//   })
//   document.querySelectorAll(`.maskOff`).forEach((el)=>{
//     const picture = el.querySelector('picture');
//     const img = el.querySelector('img');
   
//     if(img){
//       if(el.dataset.trigger){
//         img.dataset.trigger = el.dataset.trigger;
//       }
//       if(el.dataset.delay){
//         img.dataset.delay = el.dataset.delay;
//       }
//       img.classList.add('gs_reveal_scale');
//     }

//     if(picture){
//       if(el.dataset.trigger){
//         picture.dataset.trigger = el.dataset.trigger;
//       }
//       if(el.dataset.delay){
//         picture.dataset.delay = el.dataset.delay;
//       }
//       picture.classList.add('gs_reveal');
//       picture.classList.add('gs_reveal_maskOff');
//     }
    
    
//   })
//   document.querySelectorAll(`.wp-block-embed.is-type-video iframe`).forEach((el)=>{
//     el.classList.add('gs_reveal');
//     el.classList.add('gs_reveal_alpha');
//     el.setAttribute('data-delay', '5');
//   })
//   document.querySelectorAll(`.main-header-expo-image img`).forEach((el)=>{
//     gsap.set(el,{transition:'none'});
//     gsap.set(el,{transitionDelay:0});
//     el.setAttribute(el.closest('[data-trigger]') ?  'data-trigger' : 'data-trigger', el.closest('[data-trigger]') ?  el.closest('[data-trigger]').getAttribute('data-trigger') : '');
//     el.setAttribute(el.closest('[data-delay]') ?  'data-delay' : 'data-delay', el.closest('[data-delay]') ?  el.closest('[data-delay]').getAttribute('data-delay') : '0');
//     el.classList.add('gs_reveal');
//     el.classList.add('gs_reveal_scale');
//     el.offsetHeight
//     el.removeAttribute('style');
//   })
//   document.querySelectorAll(`.page-content hr`).forEach((el)=>{
//     el.classList.add('gs_reveal');
//     el.classList.add('gs_reveal_scaleX');
//   })
// }


barba.hooks.after(() => {
  
});
if ('scrollRestoration' in history) {
  history.scrollRestoration = 'manual';
}
window.addEventListener('popstate', (event) => {
  //console.log(event)
  window.scrollTo(scrollX, scrollY);
  document.documentElement.scrollTop = scrollY;
})
function GTM_push_pageview_layer() {
	
	window.dataLayer = window.dataLayer || [];
	window.dataLayer.push({
		//'event': 'Pageview',
		'event': 'page_view',
		'page_title': (document.title) ? document.title : '',
		'page_URL': (window.location.href) ?  window.location.href : '',
		'page_path': (window.location.pathname) ? window.location.pathname : '',
		'page_hash': (window.location.hash) ? window.location.hash : '',
	});
  console.log('GMT',window.dataLayer);
}

const menu_items =  document.querySelectorAll('.menu-item a');
const templateLoader = document.querySelector('.template-loader');
barba.init({
  debug: true,
  //logLevel: 'error',
  timeout: 10000,
  //cacheIgnore: ['/panier/', '/compte/', '/nos-produits/'],
  //cacheIgnore: true,
  //cacheIgnore: 'true',
  prevent: ({ el }) => el.closest("#wpadminbar") || el.closest(".pagination") || el.closest('.index-opener') || el.closest('.infos-opener'),
  // views: [{
  //   namespace: [
  //     'slider-project'
  //   ],
  //   afterEnter(data) {
  //     alert('SLIIIDER')
  //   },
    
  // },
  // {
  //   namespace: [
  //     'default'
  //   ],
  //   afterEnter(data) {
  //     alert('oki')
  //   }
  // }
  // ],
  transitions: [
   
    {
      name: 'opacity-transition',
      before(data) {
        console.log('NEXT',data.next.namespace);
        loaderTL.play('start');   
        return gsap.to(data.current.container, {
          opacity: 1,
          duration:1
        });
      },
      enter(data) {
        return new Promise(resolve => {
          if(data.next.container.querySelector('.main-header')){
            imagesLoaded(data.next.container.querySelector('.main-header'), () => {
              resolve();
            });
          }else{
            resolve();
          }
        
        });
        
      },
      
      after(data){
      
        
        setTimeout(()=>{
          
          document.body.setAttribute("class", data.next.container.getAttribute('class'));
          window.scrollTo(0, 0);
          document.documentElement.scrollTop = 0;
          initScripts();
          
          
          //const scrollAnimations = new ScrollAnimations(document.querySelector('.new-barba'));
          // gsap.set(document.querySelectorAll('.gs_reveal_show'),{transition: "none"});
          // gsap.set(document.querySelectorAll('.mask_reveal_show'),{strokeDashoffset: 0, opacity:1});
          //gsap.set(data.next.container,{willChange: "transform, opacity"});
          Array.from(document.querySelectorAll('video')).forEach((video)=>{
            checkforVideo(video);
        
          })
          loaderTL.play();
          
          ScrollTrigger.refresh()
        },1000)
        
        
      }
    },
    {
      name: 'fromToslider',
      appear() {},
      to : {
        namespace: [
          'slider-project'
          
        ]
      }, 
      from: {
        custom: (data) => {//{ trigger }
          //console.log(trigger)
          console.log(data)///{current, event, next, trigger}
            //return trigger.classList && trigger.classList.contains('main-header-link') && window.matchMedia("(min-width: 768px)").matches;
          return window.matchMedia("(max-width: 991px)").matches
        },
        // set of conditions to be fulfilled
        // to play the transition
      },
      // to : {

      //   // define rule based on multiple namespaces
      //   namespace: [
      //     'slider-project'
          
      //   ]
      // },
      before(data) {
        console.log('NEXT',data.next.namespace);
        gsap.set(templateLoader, {top : 0});
        gsap.fromTo(templateLoader, 
          {
            yPercent: 100
          },
          {
            yPercent : 0,
            overwrite : true,
            ease: "power2.inOut",
            duration : .7
          }
        )
        return gsap.to(data.current.container, {
          opacity: 1,
          duration:1
        });
      },
      enter(data) {
        return new Promise(resolve => {
          document.body.setAttribute("class", data.next.container.getAttribute('class'));
          window.scrollTo(0, 0);
          document.documentElement.scrollTop = 0;
          initScripts();

          Array.from(document.querySelectorAll('video')).forEach((video)=>{
            checkforVideo(video);
        
          })
          //loaderTL.play();
          new ScrollAnimations(document.querySelector('.new-barba'))
          ScrollTrigger.refresh()
           if(data.next.container.querySelector('.main-header')){
            imagesLoaded(data.next.container.querySelector('.main-header'), () => {
              resolve();
            });
          }else{
            resolve();
          }
         
        });
        
      },
      
      after(data){
        
        gsap.to(templateLoader, 
         
          {
            opacity : 0,
            //immediateRender: false,
            //overwrite : true,
            duration : .3,
            onComplete : ()=>{
              gsap.killTweensOf(templateLoader);
              templateLoader.removeAttribute('style');
            }
            //delay : .7
          }
        )
        
        
        
      }
    },

  ]

});

// const views = barba.views.byNamespace;
// console.log('BARBA vIEWS',views)
// const homeView = views.get('default');
// var key = Array.from(views.keys())[0]
// console.log(key)
// // call the home `afterEnter` view
// key.afterEnter();

barba.hooks.leave(() => {
  scrollX = barba.history.current.scroll.x;
  scrollY = barba.history.current.scroll.y;
  scrollX = 0;
  scrollY = 0;
});
barba.hooks.enter((data) => { // <- add data argument here
  


  var xmlString = data.next.html;
  var doc = new DOMParser().parseFromString(xmlString, "text/html");
  const head = document.querySelector("head");
  const newTags = doc.documentElement.querySelectorAll('head link[id^="domaine-style"], head meta[name], head meta[property],script[type="application/ld+json"], link[rel="canonical"], link[rel="next"], link[rel="prev"]');
  const adminBar = doc.documentElement.querySelectorAll('#wpadminbar');
  //document.querySelector("head").innerHTML = newHead.innerHTML;

  Array.from(document.querySelectorAll('head meta[name], head meta[property],script[type="application/ld+json"], link[rel="canonical"], link[rel="next"], link[rel="prev"]')).forEach((tag)=>{
    tag.parentNode.removeChild(tag);
  })
  Array.from(document.querySelectorAll('script[type="application/ld+json"]')).forEach((tag)=>{
    tag.parentNode.removeChild(tag);
  })
  Array.from(document.querySelectorAll('#wpadminbar')).forEach((tag)=>{
    tag.parentNode.removeChild(tag);
  })
  Array.from(newTags).forEach((tag)=>{
    //tag.parentNode.removeChild(tag);
    head.appendChild(tag)
  })
  Array.from(adminBar).forEach((tag)=>{
    //tag.parentNode.removeChild(tag);
    document.body.appendChild(tag)
  })

  document.body.classList.remove('index-open')
  document.body.classList.remove('infos-open')
  data.next.container.classList.add("new-barba");

  //gsap.set( data.next.container,{opacity:0})
  //gsap.to( data.next.container,{opacity:1, duration:0, delay:1.2})
  Array.from(document.querySelectorAll('video')).forEach((video)=>{
    gsap.set(video,{opacity: 0});
  })
  Array.from(menu_items).forEach((el)=>{
    el.blur();
  })
  
  Array.from(data.next.container.querySelectorAll('iframe')).forEach((el)=>{
    const src = el.getAttribute('src');
    el.setAttribute('src', '');
    el.setAttribute('src', src);
  })
  Array.from(document.querySelectorAll('.main-header')).forEach((el)=>{
    el.classList.remove('gs_reveal')
    el.classList.remove('gs_reveal_alpha')
  })
})
barba.hooks.afterEnter((data) => {
  ScrollTrigger.getAll().forEach((el)=>{
    el.kill();
  })
  if(data.trigger !== 'forward' && data.trigger !== 'back'){
    document.documentElement.scrollTop = 0;
  }
})
barba.hooks.after((data) => {
	document.querySelectorAll('form[data-afficher-si]').forEach(form => {
		document.dispatchEvent(new CustomEvent('formulaire:chargement', { detail: form }));
	});
	
	GTM_push_pageview_layer()
  
	if(typeof ga === "function"){
    console.log('test analitycs')
	  ga('set', 'page', window.location.pathname);
	  ga('send', 'pageview');
	}
});

// window.onRecaptchaLoad = function() {
//   var recaptchas = document.querySelectorAll('.g-recaptcha');
//   for (var i = 0; i < recaptchas.length; i++) {
//       grecaptcha.render(recaptchas[i], {
//           sitekey: recaptchas[i].getAttribute('data-sitekey')
//       });
//   }
// }


let resizeTimeOut = null;
window.addEventListener('resize', ()=>{
  clearTimeout(resizeTimeOut);
  if(!document.body.classList.contains('resizing')){
    document.body.classList.add('resizing')
  }
  resizeTimeOut = setTimeout(()=>{
    document.body.classList.remove('resizing')
  },700)
})