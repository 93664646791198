import { gsap } from "gsap";
import SplitType from 'split-type'
import { ScrollTrigger } from "gsap/ScrollTrigger";
import { CSSRulePlugin } from "gsap/CSSRulePlugin";
import { Observer } from "gsap/Observer";
import { ScrollToPlugin } from "gsap/ScrollToPlugin";

//import { getParents } from "./utils/shortcuts";
import {wrapInner} from "./utils/shortcuts";
import "objectFitPolyfill";
//import {disableScroll} from './utils/scrollControl';
//import {enableScroll} from './utils/scrollControl';
import { lineHoverEffect } from "./line-hover-effect";
gsap.registerPlugin(ScrollTrigger);
gsap.registerPlugin(ScrollTrigger, Observer);
gsap.registerPlugin(CSSRulePlugin);

gsap.registerPlugin(ScrollToPlugin);
//const imagesLoaded = require('imagesloaded');

export class ScrollAnimations {
  constructor(container) {
     ///valeur par défaut et spread
    this.winWidth = window.innerWidth;
    this.winWidthPin = window.innerWidth;
    this.winWidthSplit = window.innerWidth;
    const _this = this;
    this.container = container ? container : document;
    this.lang = document.documentElement.lang;
    this.splitTypeArray = [];
    this.spliteLineSlideArray = [];
    //console.log(this.splitTypeArray)
    this.splitTimeOut = null;
    this.scrollTriggerArray = [];
    this.markup();
    this.pinScroll();
    
    // this.stickyCardsInit();
    // this.stickyHightlightsInit();
    this.prlxAnimation();
    this.splitTypeInit();
    document.querySelectorAll(`.single-page-content .content p,
      
      .single-page-content .content li,
      .single-page-content >h2,
      .single-page-content >h3,
      .single-page-content > h5,
      .single-page-content table,
      .single-page-content form,
      .single-page-content button,
      .single-page-content iframe,
      .single-page-content cite,
      .single-page-content .content figcaption,
      .single-page-content form
      `).forEach((el)=>{
      
      
      el.classList.add('gs_reveal_fromBottom');
      el.offsetHeight;
      el.classList.add('gs_reveal');
    })
    // document.querySelectorAll(`.single-page-content figure img,.single-page-content picture img 
    //   `).forEach((el)=>{
    //   el.classList.add('gs_reveal');
    //   el.classList.add('gs_reveal_scale');
    // })


    // if(document.body.classList.contains('home')){
    //   gsap.set('.site-menu-main .menu-item a > span',{transition : 'none'})
    //   gsap.fromTo('.site-menu-main .menu-item a > span',
    //   {
    //     opacity: 0,
    //     y: 30
    //   },
    //   {
    //     opacity: 1 ,
    //     y:0,
    //     duration: .7,
    //     stagger : .05,
    //     clearProps: true,
        
    //   })
    // }
    
    
    //this.splitLineSlideInit();
    //setTimeout(()=>{
    this.revealScroll();
    //},100)
    
  

    this.revealMask();
    this.imgMask();

    
    document.querySelectorAll('.number-animate').forEach(function(el){
      this.numberAnimation(el);
    }, this);
    
    lineHoverEffect(this.container);
    
    // if(this.container === document || this.container === document.querySelector('.new-barba')){
    //   let main = document.querySelector(".site");
     
    // }
    
  }
  markup(){
    
    // this.container.querySelectorAll('#footer-menu li, .footer-copyright p').forEach((el, i)=>{
    //   gsap.set(el,{transition:'none'});
    //   gsap.set(el,{transitionDelay:0});
    //   gsap.set(el,{opacity:0});
    //   el.setAttribute('data-trigger-start', 'top bottom');
    //   el.setAttribute('data-trigger', '#footer');
    //   el.classList.add('gs_reveal');
    //   el.classList.add('gs_reveal_fromBottom');
    //   el.offsetHeight
    //   el.removeAttribute('style');
    //   el.setAttribute('data-delay', 1+i);
  
    // })
    this.container.querySelectorAll('.site-menu-secondary-inner-info p').forEach((el)=>{
      el.dataset.split = 'line';
    })
    this.container.querySelectorAll(`.maskOff`).forEach((el)=>{
      const picture = el.querySelector('picture');
      const img = el.querySelector('img');
     
      if(img){
        if(el.dataset.trigger){
          img.dataset.trigger = el.dataset.trigger;
        }
        if(el.dataset.delay){
          img.dataset.delay = el.dataset.delay;
        }
        img.classList.add('gs_reveal');
        img.offsetHeight
        img.classList.add('gs_reveal_scale');
        
      }
  
      if(picture){
        if(el.dataset.trigger){
          picture.dataset.trigger = el.dataset.trigger;
        }
        if(el.dataset.delay){
          picture.dataset.delay = el.dataset.delay;
        }
        picture.classList.add('gs_reveal');
        picture.offsetHeight
        picture.classList.add('gs_reveal_maskOff');
      }
      
      
    })
    this.container.querySelectorAll(`.wp-block-embed.is-type-video iframe`).forEach((el)=>{
      el.classList.add('gs_reveal');
      el.offsetHeight
      el.classList.add('gs_reveal_alpha');
      el.setAttribute('data-delay', '5');
    })
    
    this.container.querySelectorAll(`.page-content hr`).forEach((el)=>{
      el.classList.add('gs_reveal');
      el.offsetHeight
      el.classList.add('gs_reveal_scaleX');
    })
  }
  imgMask(){
    Array.from(this.container.querySelectorAll('.img-reveal-mask')).forEach((elem) =>{
      const maskTL = gsap.timeline({paused: true});
      const children = elem.children[0];
      const selector = elem.dataset.selector ? elem.querySelector(elem.dataset.selector) : elem.querySelector('img');
      console.log(children, selector);
      gsap.set(children, {
        scaleX:  elem.dataset.scaleX ?  elem.dataset.scaleX :  .5,
        scaleY: elem.dataset.scaleY ?  elem.dataset.scaleY : .1,
      })
      gsap.set(selector, {
        scaleX: 1/gsap.getProperty(children, 'scaleX'),
        scaleY: 1/gsap.getProperty(children, 'scaleY'),
      })
      maskTL.fromTo(children,
        {
        scaleX : elem.dataset.scaleX ?  elem.dataset.scaleX :  .5,
        scaleY: elem.dataset.scaleY ?  elem.dataset.scaleY : .1
        },
        {
        scaleX : 1,
        scaleY: 1,
        ease: 'none',
        // onStart: ()=>{
        //   gsap.set(selector, {
        //     scaleX: 1/gsap.getProperty(children, 'scaleX'),
        //     scaleY: 1/gsap.getProperty(children, 'scaleY'),
        //   })
        // },
        onRefresh: ()=>{
          gsap.set(selector, {
            scaleX: 1/gsap.getProperty(children, 'scaleX'),
            scaleY: 1/gsap.getProperty(children, 'scaleY'),
          })
        },
        onUpdate: ()=>{
          //console.log(this)
          // console.log(gsap.getProperty(elem.querySelector('picture'), 'scaleX'));
          gsap.set(selector, {
            scaleX: 1/gsap.getProperty(children, 'scaleX'),
            scaleY: 1/gsap.getProperty(children, 'scaleY'),
          })
        }
         
        }
      )
      // gsap.set(selector, {
      //   scaleX: 1/gsap.getProperty(children, 'scaleX'),
      //   scaleY: 1/gsap.getProperty(children, 'scaleY'),
      // })
      console.log('end', elem.dataset.end, elem)
      let maskTLscrolltriggger = ScrollTrigger.create({
        animation: maskTL,
        trigger: elem.dataset.trigger ?  elem.dataset.trigger: elem,
        start: elem.dataset.start ?  elem.dataset.start: 'top bottom',
        end: elem.dataset.end ?  elem.dataset.end :'center center',
        scrub: .3,
        invalidateOnRefresh: true,
        refreshPriority : elem.dataset.refresh ? parseInt(elem.dataset.refresh) : 0,
        markers: elem.dataset.debug ? true: false,
        onRefresh: ()=>{
          gsap.set(selector, {
            scaleX: 1/gsap.getProperty(children, 'scaleX'),
            scaleY: 1/gsap.getProperty(children, 'scaleY'),
          })
        },
      });
      
    
    })
  }
  pinScroll(){

    Array.from(this.container.querySelectorAll('[data-pin]')).forEach((elem) =>{
     // console.log('PIN', document.querySelector('.main-header-title'));
     const breakpoint = elem.dataset.breakpoint ? elem.dataset.breakpoint : "(min-width: 0px)";
     
     //console.log("BREAKPOINT", breakpoint)
     const triggerEl = elem.dataset.triggerPin ?  document.querySelector(elem.dataset.triggerPin) : elem;
      ScrollTrigger.matchMedia({
        [breakpoint] : ()=>{
          
          //console.log('MARGINTOP',window.getComputedStyle(triggerEl, null).getPropertyValue('margin-top'))
          const pinTrigger = ScrollTrigger.create({
            //id: elem.getAttribute("data-id") ? "#"+elem.getAttribute("data-id") : null,
            trigger: elem.dataset.triggerPin ? elem.dataset.triggerPin: elem,
            endTrigger: elem.dataset.triggerPinEnd ? elem.dataset.triggerPinEnd : elem,
            start: ()=>{
              
              let start= elem.dataset.pinNavOffset ? `top top+=${document.querySelector('#site-navigation').offsetHeight + parseInt(window.getComputedStyle(triggerEl, null).getPropertyValue('margin-top'))}` : (elem.dataset.pinStart ? elem.dataset.pinStart :  0);
              //console.log('STARTTTTTTTTTTT',start);
              //console.log('offsetttt',elem.dataset.pinNavOffset);
              if(document.querySelector('#wpadminbar')){
                start= elem.dataset.pinNavOffset ? `top top+=${document.querySelector('#site-navigation').offsetHeight + parseInt(window.getComputedStyle(triggerEl, null).getPropertyValue('margin-top')) + parseInt(document.querySelector('#wpadminbar').offsetHeight)}` : elem.dataset.pinStart ? elem.dataset.pinStart :  0;
                //console.log('START2',start);
              }
             
             // console.log(document.querySelector('#site-navigation').offsetHeight+parseInt(window.getComputedStyle(triggerEl, null).getPropertyValue('margin-top')))
              return start;
              //return elem.dataset.pinNavOffset ? `top top+=${document.querySelector('#site-navigation').offsetHeight+parseInt(window.getComputedStyle(triggerEl, null).getPropertyValue('margin-top'))}` : elem.dataset.pinStart ? elem.dataset.pinStart :  0
            },
            end: ()=> {
              //console.log("ENDDD",(document.querySelector(elem.dataset.triggerPinEnd)))
              if(elem.dataset.pinEnd){
               // console.log('PINEND')
              }else{
                if(document.querySelector(elem.dataset.triggerPinEnd)){
                 // console.log('End trigg', document.querySelector(elem.dataset.triggerPinEnd).offsetHeight,elem.offsetHeight)
                }
              }
              console.log('END PINNNNN',elem.dataset.pinEnd ? (elem.dataset.pinEnd) : (/*elem.dataset.triggerEnd && */document.querySelector(elem.dataset.triggerPinEnd))  ? `+=${document.querySelector(elem.dataset.triggerPinEnd).offsetHeight  - elem.offsetHeight}` : `+=0`)
              return elem.dataset.pinEnd ? (elem.dataset.pinEnd) : (/*elem.dataset.triggerEnd && */document.querySelector(elem.dataset.triggerPinEnd))  ? `+=${document.querySelector(elem.dataset.triggerPinEnd).offsetHeight  - elem.offsetHeight}` : `+=0`
            },
            
            preventOverlaps: true,
            //pinnedContainer: elem.dataset.container ? elem.dataset.container : null,
            anticipatePin: .2,
            //pinReparent: true,
            //pinType: 'transform',
            refreshPriority: elem.dataset.refresh ? elem.dataset.refresh* 1 : 0,
            pin: elem,
            
            // onUpdate: ()=> console.log(window.pageYOffset),
            onRefresh: ()=>{
              //console.log(elem,"reefreshhh")
              
            },
            //markers: true,
            //fastScrollEnd: true,
            invalidateOnRefresh: true,
            pinSpacing: elem.dataset.pinSpacing ? elem.dataset.pinSpacing : false,
            
            
            toggleClass: "active",
            //scrub: 1,
          });
          ScrollTrigger.sort();
         // ScrollTrigger.refresh()
         // console.log(pinTrigger);
          let pinTimeOut = null;
          // window.addEventListener('resize', ()=>{
          //   if(window.innerWidth !== this.winWidthPin ){
          //     this.winWidthPin = window.innerWidth;
          //     pinTrigger.disable();
          //     clearTimeout(pinTimeOut);
          //     pinTimeOut = setTimeout(()=>{
          //       pinTrigger.enable();
          //     },10)
              
          //   }
            
          // })
        }
      })
      
      // Array.from(elem.querySelectorAll('.gs_reveal')).forEach((el)=>{
      //   console.log(elem.parentNode, 'PARENT')
      //    el.classList.remove('gs_reveal');
      //   // el.offsetHeight;
      //    el.classList.add('gs_reveal_show');
      //  })
    })
  }
  revealScroll(){
    
    Array.from(this.container.querySelectorAll(".img")).forEach(function(elem) {
      ScrollTrigger.create({
        trigger: elem,
        onEnter: () => {
          ScrollTrigger.refresh(true);
        }
      })
    })

    let mm = gsap.matchMedia(),
    breakPoint = 768;
  
    mm.add(
      {
        // set up any number of arbitrarily-named conditions. The function below will be called when ANY of them match.
        isDesktop: `(min-width: ${breakPoint}px)`,
        isMobile: `(max-width: ${breakPoint - 1}px)`,
        
      },
      (context) => {
        // context.conditions has a boolean property for each condition defined above indicating if it's matched or not.
        let { isDesktop, isMobile } = context.conditions;
        const scrollHorizontal = document.querySelector('.scroll-horizontal') ? true : false;
        Array.from(this.container.querySelectorAll(".gs_reveal")).forEach((elem) =>{
         // console.log(isDesktop, scrollHorizontal,elem.closest('.page-content'),!elem.dataset.ignoreHorizontal)
          if(elem.classList.contains('gs_reveal_anim')){ 
            const tlReveal = gsap.timeline({paused: true});
            gsap.set(elem, {transition : 'none'});
            tlReveal.fromTo(elem, {
              opacity: 0,
              y: elem.classList.contains('gs_reveal_fromBottom') || elem.classList.contains('gs_reveal_rotate')  ? (elem.dataset.startY ? elem.dataset.startY : 20) : 0,
              yPercent: (elem.classList.contains('gs_reveal_fromBottom') || elem.classList.contains('gs_reveal_rotate')) && elem.dataset.startYpercent  ? (elem.dataset.startYpercent ? elem.dataset.startYpercent : 0) : 0,
              rotate:  elem.classList.contains('gs_reveal_rotate') ?  (elem.dataset.startRotate ? elem.dataset.startRotate : -20) : 0,
              scale: elem.classList.contains('gs_reveal_scale') ? 1.1 : 1
            },
            {
              opacity: 1,
              y: 0,
              yPercent : 0,
              rotate:  elem.classList.contains('gs_reveal_rotate') ? 0: 0,
              scale: 1,
              duration: elem.dataset.duration ? elem.dataset.duration : 1,
              ease: elem.dataset.ease ? elem.dataset.ease : 'power4.out',
              delay: elem.dataset.delay ? elem.dataset.delay*.1 : 0 
            })
           
            ScrollTrigger.create({
              animation: tlReveal,
              
              markers: elem.dataset.markers ? true : false,
              trigger: elem.dataset.trigger ? elem.dataset.trigger : elem,
              start: ()=> elem.dataset.triggerStart ? elem.dataset.triggerStart : (isDesktop && scrollHorizontal && elem.closest('.page-content')) ? "left right-=10%" : "top bottom-=10%" ,

              once: elem.dataset.once ? ((elem.dataset.once === "false") ? false : true) : true,
              toggleClass: {targets: elem.dataset.toggle ?  elem.dataset.toggle : elem, className: "active"},
              refreshPriority: elem.dataset.refresh ? elem.dataset.refresh* 1 : 0,
            });
          }else{
       
            ScrollTrigger.create({
             // invalidateOnRefresh: true,
    
              refreshPriority: elem.dataset.refresh ? elem.dataset.refresh* 1 : 0,
              scroller: elem.closest('[data-scroller]') ? elem.closest('[data-scroller]') : window,
              trigger: elem.dataset.trigger ? elem.dataset.trigger : elem,
              start: ()=> elem.dataset.triggerStart ? elem.dataset.triggerStart : (isDesktop && scrollHorizontal && elem.closest('.page-content')) ? "left right-=10%" : "top bottom-=10%" ,
              end: ()=>elem.dataset.triggerEnd ? elem.dataset.triggerEnd : (isDesktop && scrollHorizontal && elem.closest('.page-content')) ? "right center" : "bottom top" ,
              containerAnimation: (isDesktop && scrollHorizontal && elem.closest('.page-content') && !elem.dataset.ignoreHorizontal) ? this.containerAnimation : null,
              markers: elem.dataset.markers ? true : false,
              once: elem.dataset.once ? ((elem.dataset.once === "false") ? false : true) : true,
              toggleClass: {targets: elem.dataset.toggle ?  elem.dataset.toggle : elem, className: "active"},
              onEnter: () => { console.log(isDesktop && scrollHorizontal && elem.closest('.page-content') && !elem.dataset.ignoreHorizontal);return elem.classList.add("gs_reveal_show") /*$console.log('enter', elem);if(elem.classList.contains('gs_reveal_show')){elem.classList.remove('gs_reveal_show'); elem.offsetHeight;};*/ /*_this.animateFrom(elem) */}, 
              onEnterBack : () => { return elem.dataset.once ? (elem.dataset.once === "false" ?  elem.classList.remove("gs_reveal_show") : false ) : false},
              onLeave : () => {  return elem.dataset.once ? (elem.dataset.once === "false" ?  elem.classList.add("gs_reveal_show") : false ) : false},
              onLeaveBack : () => { return elem.dataset.once ? (elem.dataset.once === "false" ?  elem.classList.remove("gs_reveal_show") : false ) : false},//return elem.classList.add("gs_reveal_show")}
            });
          }
        });

        return () => {
          Array.from(this.container.querySelectorAll(".gs_reveal")).forEach((elem) =>{
            elem.classList.remove('gs_reveal_show')
          })
          // optionally return a cleanup function that will be called when none of the conditions match anymore (after having matched)
          // it'll automatically call context.revert() - do NOT do that here . Only put custom cleanup code here.
        };
    })
  
    //////////////////////

   
    
  }
  numberAnimation(el) {
    const _this = this;
    let stringEl = el.innerHTML;
    let splitArray = (this.lang === 'en-GB') ? stringEl.match(/[0-9]+(\.[0-9][0-9]?)?/g) : stringEl.match(/[0-9]+(\,[0-9][0-9]?)?/g); //
    let value = "";
    for(var i = 0 ; i < splitArray.length; i++){
      value = splitArray[i];
      stringEl = stringEl.replace(value, `<span class="number">${value}</span>`);
    }
    el.innerHTML = stringEl;
    
    ScrollTrigger.create({
      trigger: el.closest('[data-trigger]') ? (el.closest('[data-trigger]').dataset.trigger ? el.closest('[data-trigger]').dataset.trigger : el) : el,
      start: "top bottom-=20%",
      //scroller: document.querySelector('#content'),
      //once: "true",
      onEnter: function() { 
        value = value.replace(',', '.')
        var Cont={val:0} , NewVal = parseFloat(value) ;
        //console.log(NewVal)
        gsap.to(Cont,
          {
          val:NewVal,
          onUpdate:()=>{
            //console.log(NewVal)
            const numberEl = el.querySelector(".number");
            if( Math.round(NewVal) !== NewVal ){
              console.log(this.lang)
              let numberFloat =  Cont.val.toFixed(countDecimals(NewVal)).toString()
              numberFloat = this.lang === 'en-GB' ? numberFloat : numberFloat.replace('.', ',')
              numberEl.innerHTML= numberFloat
              //el.querySelector(".number").innerHTML= Cont.val.toFixed(1).toString().replace('.', ',')
            }else{
              numberEl.innerHTML= Math.round(Cont.val)
            }
          },
          duration: 1
        }
      );
      }, 
    });
  }
  revealMask(){
    Array.from(this.container.querySelectorAll(".mask_reveal")).forEach((mask, i) =>{
      const length  = mask.getTotalLength();
      const delay = mask.dataset.delay ? parseInt(mask.dataset.delay) : 0;
      gsap.set(mask, {strokeDasharray:length});
      gsap.set(mask, {strokeDashoffset:length, opacity: 0});
      ScrollTrigger.create({
        trigger: mask.dataset.trigger ? mask.dataset.trigger : mask,
        start: mask.dataset.start ? mask.dataset.start : "top bottom-=15%",
        //markers: true,
        onEnter: () => { 
          mask.classList.add("mask_reveal_show");
          const tl = gsap.timeline();
          tl.addLabel('start');
          tl.to(mask, {opacity: 1, duration:0, delay: .2 * delay}, 'start'); // Set the length to stroke-dashoffset in the styles

          tl.to(mask, {strokeDashoffset: 0,  duration: mask.dataset.duration ? mask.dataset.duration : 1, delay: .2 * delay, ease: 'slow (.1, .7, true)'}, 'start'); // Set the length to stroke-dashoffset in the styles
        }, 
       
      });
    })
  }
  splitTypeInit(){
   
    this.splitTypeArray = [];
   // console.log(this.splitTypeArray)
    
    Array.from(this.container.querySelectorAll('[data-split]')).forEach((el, index) => {
      
      this.splitTypeArray.push({splitEl : new SplitType(el, { types: el.dataset.split, tagName: 'span' }), DOMEl: el, trigger: el.dataset.trigger ? el.dataset.trigger : '', delay: el.dataset.delay ? el.dataset.delay : '2', ww :window.innerWidth});
      
      Array.from(el.querySelectorAll('.line')).forEach((line, i)=>{
        wrapInner(line, 'span', 'class', 'line-inner');
        const trigger = el.dataset.trigger ? el.dataset.trigger : '';
       // console.log('trig', trigger, el)
        Array.from(line.querySelectorAll('.line-inner')).forEach((lineInner, y)=>{
          lineInner.classList.add('gs_reveal');
          lineInner.setAttribute('data-delay', parseInt(el.dataset.delay ? el.dataset.delay : '0')+i);
          if(el.dataset.once){
            lineInner.setAttribute('data-once', el.dataset.once);
          }
          if(el.dataset.triggerStart){
            lineInner.setAttribute('data-trigger-start', el.dataset.triggerStart);
          }
          lineInner.setAttribute('data-trigger', trigger != "" ? trigger : '');
          lineInner.classList.add('gs_reveal_slideUp');
        })
      })
      el.classList.add('split-line-ready');
      let tOut = null;
      const resizeObserver = new ResizeObserver(
        ()=>{
          //console.log('observer')
          //if(this.windowWidth !== window.innerWidth){
            //this.windowWidth = window.innerWidth;
            tOut = setTimeout(()=>{
              clearTimeout(tOut);
              if(document.body.contains(el)){
                this.resizeSplitEvent2(el, this.splitTypeArray[index]);
              }
              
            }, 100)
            
            //this.resizeSplitEvent();
          //}
        }
      )
      resizeObserver.observe(document.body)
    })
    
    
    
    
  }
  splitLineSlideInit(){
    this.spliteLineSlideArray = [];
    Array.from(this.container.querySelectorAll('[data-line-slide]')).forEach((el, index) => {
      console.log("test")
      this.spliteLineSlideArray.push({splitEl : new SplitType(el, { types: el.dataset.lineSlide, tagName: 'span' }), DOMEl: el, trigger: el.dataset.trigger ? el.dataset.trigger : '', delay: el.dataset.delay ? el.dataset.delay : '2'});
      Array.from(el.querySelectorAll(".line")).forEach((line)=>{
        const mask = document.createElement("span");
        mask.classList.add("line-mask");
        line.appendChild(mask);
        const maskAnim = gsap.timeline({paused: true});
        maskAnim.fromTo(mask, 
          { 
            scaleX : 1
          },
          {
            scaleX : 0,
            force3D:true
        })
        this.scrollTriggerArray.push(ScrollTrigger.create({
          animation: maskAnim,
          //invalidateOnRefresh: true,
          trigger: line,
          start: el.dataset.triggerStart ? el.dataset.triggerStart : `top center+=${line.offsetHeight/2}px`,
          end: el.dataset.triggerEnd ? el.dataset.triggerEnd : `top center-=${line.offsetHeight/2}px`,
          scrub: .3,
          //id: "scrub",
          toggleClass: "active",
        }));
      })
    })
  }

  // resizeSplitEvent(){
  //   //this.windowWidth = window.innerWidth;
  //   if(window.innerWidth !== this.winWidthSplit ){
  //     this.winWidthSplit = window.innerWidth;
  //     this.scrollTriggerArray = [];
  //     clearTimeout(this.splitTimeOut);
  //     this.splitTimeOut = setTimeout(()=>{
  //       this.splitTypeArray.forEach((split)=>{
  //         //console.log('resize',split.DOMEl.dataset.once !== "false", split.DOMEl);
  //         const isVisible = (split.DOMEl.querySelector('.gs_reveal_show') &&  (split.DOMEl.dataset.once !== "false")) ? true : false;
  //         split.splitEl.revert();
  //         split.splitEl.split();
  //         Array.from(split.DOMEl.querySelectorAll('.line')).forEach((line, i)=>{
  //           wrapInner(line, 'span', 'class', 'line-inner');
  //           //const trigger = el.dataset.trigget ? el.dataset.trigget : '';
  //           Array.from(this.container.querySelectorAll('.line-inner')).forEach((lineInner, y)=>{
  //             lineInner.classList.add('gs_reveal');
  //             //console.log('delay',  parseInt(split.delay)+parseInt(i))
  //             lineInner.setAttribute('data-delay', parseInt(split.delay)+parseInt(i));
  //             lineInner.classList.add('gs_reveal_slideUp');
  //             console.log('isVisible', isVisible, split.DOMEl)
  //             isVisible && lineInner.classList.add('gs_reveal_show');
  //             //if(this.container.querySelector('.split.trigger')){
  //               this.scrollTriggerArray.push(ScrollTrigger.create({
  //                 trigger: split.trigger != "" ? split.trigger : '',
  //                 start: split.DOMEl.dataset.triggerStart ? split.DOMEl.dataset.triggerStart : "top bottom-=10%",
  //                 onEnter: function() { lineInner.classList.add("gs_reveal_show")/*_this.animateFrom(elem) */}, 
  //               }));
  //               ScrollTrigger.refresh();
  //             //}
  //           })
  //         })
  //       })
  //       this.spliteLineSlideArray.forEach((split)=>{
  //         split.splitEl.revert();
  //         this.splitLineSlideInit();
  //       })
  //       ScrollTrigger.refresh();
  //     }, 200)
  //   }
    
  // }
  resizeSplitEvent2(el, split){
    if(window.innerWidth !== split.ww ){
      //console.log('resizeSplitEvent2',split.ww, window.innerWidth)
      split.ww = window.innerWidth;
      //console.log(split.ww, window.innerWidth)
      const isVisible = (split.DOMEl.querySelector('.gs_reveal_show') &&  (split.DOMEl.dataset.once !== "false")) ? true : false;
      //console.log("SPLITTTTEL",split.splitEl)
      split.splitEl.revert();
      split.splitEl.split();
      if(split.DOMEl.closest('.lines-hover-effect')){
        //console.log('PARENT LINE HOIVER EFFECT',split.DOMEl.closest('.lines-hover-effect'),split.DOMEl.closest('.lines-hover-effect').parentNode)
        lineHoverEffect(split.DOMEl.closest('.lines-hover-effect').parentNode);
      }
      
      Array.from(el.querySelectorAll('.line')).forEach((line, i)=>{
        wrapInner(line, 'span', 'class', 'line-inner');
        //const trigger = el.dataset.trigget ? el.dataset.trigget : '';
        Array.from(line.querySelectorAll('.line-inner')).forEach((lineInner, y)=>{
          lineInner.classList.add('gs_reveal');
          //console.log('delay',  parseInt(split.delay)+parseInt(i))
          lineInner.setAttribute('data-delay', parseInt(split.delay)+parseInt(i));
          if(el.dataset.once){
            lineInner.setAttribute('data-once', el.dataset.once);
          }
          if(el.dataset.triggerStart){
            lineInner.setAttribute('data-trigger-start', el.dataset.triggerStart);
          }
          lineInner.classList.add('gs_reveal_slideUp');
          lineInner.setAttribute('data-trigger', el.dataset.trigger != "" ? el.dataset.trigger : '');
          isVisible && lineInner.classList.add('gs_reveal_show');
          //if(this.container.querySelector('.split.trigger')){
            // if(el.classList.contains('single-summary-title')){
            //   console.log(el.dataset.once)
            // }
            if(el){
            ScrollTrigger.create({
              once: el.dataset.once ? ((el.dataset.once === "false") ? false : true) : true,

              trigger: el.dataset.trigger != "" ? el.dataset.trigger : '',
              start: el.dataset.triggerStart ? el.dataset.triggerStart : "top bottom-=10%",
              onEnter: () => { return lineInner.classList.add("gs_reveal_show") /*$console.log('enter', el);if(el.classList.contains('gs_reveal_show')){el.classList.remove('gs_reveal_show'); el.offsetHeight;};*/ /*_this.animateFrom(el) */}, 
              onEnterBack : () => { return el.dataset.once ? (el.dataset.once === "false" ?  lineInner.classList.remove("gs_reveal_show") : false ) : false},
              onLeave : () => { return el.dataset.once ? (el.dataset.once === "false" ?  lineInner.classList.add("gs_reveal_show") : false ) : false},
              onLeaveBack : () => { return el.dataset.once ? (el.dataset.once === "false" ?  lineInner.classList.remove("gs_reveal_show") : false ) : false}
            });
            }
            //ScrollTrigger.refresh();
          //}
        })
      })
      
        ScrollTrigger.refresh();
    }
    
  }

  prlxAnimation() {
    
    Array.from(this.container.querySelectorAll('[data-prlx]')).forEach((el, index) => {
      const breakpoint = el.dataset.breakpoint ? el.dataset.breakpoint : "(min-width: 0px)";
      
      ScrollTrigger.matchMedia({
       
        [breakpoint]: () => {
          //console.log('mobile')
          //tl.kill();
          //console.log((el.dataset.prlx === "px") && el.dataset.start && !el.dataset.prlxDesktop)
          let tl = gsap.timeline({paused: true});
          tl.fromTo(el, 
            { 
             // scaleX : el.dataset.prxlScalex ? 0 : 1,
              y : ((el.dataset.prlx === "px") && el.dataset.start && !el.dataset.prlxDesktop && (el.dataset.direction !== "vertical" || !el.dataset.direction)) ? el.dataset.start : 0,
              yPercent: ((el.dataset.prlx === "%") && el.dataset.start && !el.dataset.prlxDesktop && (el.dataset.direction !== "vertical" || !el.dataset.direction))  ? el.dataset.start : 0,
              x : ((el.dataset.prlx === "px") && el.dataset.start && !el.dataset.prlxDesktop && el.dataset.direction === "vertical") ? el.dataset.start : 0,
              xPercent: ((el.dataset.prlx === "%") && el.dataset.start && !el.dataset.prlxDesktop && el.dataset.direction === "vertical")  ? el.dataset.start : 0, 
              scaleX : el.dataset.prlxScalex ? 0 : 1,
              opacity : el.dataset.prlxOpacityOut ? 1 : 1,
            },  
            {
             // scaleX : el.dataset.prlxScalex ? 1 : 1,
              y : ((el.dataset.prlx === "px") && el.dataset.end && !el.dataset.prlxDesktop && (el.dataset.direction !== "vertical" || !el.dataset.direction)) ? el.dataset.end : 0,
              yPercent: ((el.dataset.prlx === "%") && el.dataset.end && !el.dataset.prlxDesktop && (el.dataset.direction !== "vertical" || !el.dataset.direction))  ? el.dataset.end : 0,
              x : ((el.dataset.prlx === "px") && el.dataset.end && !el.dataset.prlxDesktop && el.dataset.direction === "vertical") ? el.dataset.end : 0,
              xPercent: ((el.dataset.prlx === "%") && el.dataset.end && !el.dataset.prlxDesktop && el.dataset.direction === "vertical")  ? el.dataset.end : 0,
              scaleX:  el.dataset.prlxScalex ? 1 : 1,
              opacity : el.dataset.prlxOpacityOut ? 0 : 1,
              ease: el.dataset.prlxEase ? el.dataset.prlxEase : "none" ,
              force3D:true
          })
          let scrolltriggerTl = ScrollTrigger.create({
            animation: tl,
            id: el.getAttribute("data-id") ? "#"+el.getAttribute("data-id") : null,
            //refreshPriority: -10,
            //invalidateOnRefresh: true,
            trigger: el.getAttribute("data-trigger-prlx") ? el.getAttribute("data-trigger-prlx") : el,
            endTrigger:  el.getAttribute("data-trigger-end-prlx") ? el.getAttribute("data-trigger-end-prlx") : (el.getAttribute("data-trigger-prlx") ? el.getAttribute("data-trigger-prlx") : el),
            start: el.dataset.triggerStartPrlxValue ? el.dataset.triggerStartPrlxValue : "top bottom",
            end: el.dataset.triggerEndPrlxValue ? el.dataset.triggerEndPrlxValue : "bottom top-=40px",
            scrub: .3,
            //onToggle: self => console.log("toggled, isActive:", self.isActive),
            //triggerEnd:  el.getAttribute("data-trigger-end-prlx") ? el.getAttribute("data-trigger-end-prlx") : el,
            //id: "scrub",
            //markers: true,
            toggleActions : el.getAttribute('data-toogle') ? el.getAttribute('data-toogle') : 'play none none none',
            toggleClass: {targets: [el, el.getAttribute("data-trigger-prlx") ? el.getAttribute("data-trigger-prlx") : el], className: "activeprlx"},
          });
          return function() {
            tl.kill(); 
            
            gsap.killTweensOf(el);
            el.removeAttribute('style');
            // other cleanup code can go here. 
          };
        },
        
        // "(min-width: 768px)": () => {
        //   //console.log('desk')
        //  // tl.kill();
        //   let tl = gsap.timeline({paused: true});
        //   tl.fromTo(el, 
        //     { 
        //       y : ((el.dataset.prlx === "px") && el.dataset.start && (el.dataset.direction !== "vertical" || !el.dataset.direction)) ? el.dataset.start : 0,
        //       yPercent: ((el.dataset.prlx === "%") && el.dataset.start && (el.dataset.direction !== "vertical" || !el.dataset.direction))  ? el.dataset.start : 0,
        //       x : ((el.dataset.prlx === "px") && el.dataset.start && el.dataset.direction === "vertical") ? el.dataset.start : 0,
        //       xPercent: ((el.dataset.prlx === "%") && el.dataset.start && el.dataset.direction === "vertical")  ? el.dataset.start : 0, 
        //       force3D:true 
        //     }, 
              
        //     {
        //       y : ((el.dataset.prlx === "px") && el.dataset.end && (el.dataset.direction !== "vertical" || !el.dataset.direction)) ? el.dataset.end : 0,
        //       yPercent: ((el.dataset.prlx === "%") && el.dataset.end && (el.dataset.direction !== "vertical" || !el.dataset.direction))  ? el.dataset.end : 0,
        //       x : ((el.dataset.prlx === "px") && el.dataset.end && el.dataset.direction === "vertical") ? el.dataset.end : 0,
        //       xPercent: ((el.dataset.prlx === "%") && el.dataset.end && el.dataset.direction === "vertical")  ? el.dataset.end : 0,
        //       force3D:true
        //     }
        //   )
        //   console.log(el,el.dataset.triggerStartPrlx)
        //   let scrolltriggerTl = ScrollTrigger.create({
        //     animation: tl,
        //     //invalidateOnRefresh: true,
        //     trigger: el.getAttribute("data-trigger-prlx") ? el.getAttribute("data-trigger-prlx") : el,
        //     start: el.dataset.triggerStartPrlx ? el.dataset.triggerStartPrlx : "top bottom",
        //     end: el.dataset.triggerEndPrlx ? el.dataset.triggerEndPrlx : "bottom top+=40px",
        //     scrub: .3,
        //     //markers: true,
        //     //id: "scrub",
        //     toggleClass: "active",
        //   });
        // }
      })
      
    });

  }
  resizeEvents(){
    //ScrollTrigger.sort();
    ScrollTrigger.refresh();
  }
  
  
  
}

