import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
gsap.registerPlugin(ScrollTrigger);

export class Menu {
  constructor (btn, menuInst, headerInst, searchBtn, searchClose){ ///valeur par défaut et spread
    const _this = this;
    this.mainContent = document.querySelector('#content');
		this.menuOpener = document.querySelector(btn)
    this.menu = document.querySelector(menuInst)
    this.header = document.querySelector(headerInst)
    this.parentChildMenu = this.menu.querySelectorAll('.menu-item-has-children > a, .menu-item-has-children > button ')
    this.menuItems = this.menu.querySelectorAll('.menu-item a');
    

    this.focusableElementsArray = [
      '[href]',
      'button:not([disabled])',
      'input:not([disabled])',
      'select:not([disabled])',
      'textarea:not([disabled])',
      '[tabindex]:not([tabindex="-1"])',
    ];

    this.branding = this.header.querySelector('.site-branding')
    this.keyCodes = {
      tab: 9,
      enter: 13,
      escape: 27,
    };
    
    Array.from(document.querySelectorAll('.index-opener')).forEach((el)=>{
      el.addEventListener('click', (e)=>{
        e.preventDefault();
        document.body.classList.add('index-open');
      })
    })
    Array.from(document.querySelectorAll('.site-menu-index-close')).forEach((el)=>{
      el.addEventListener('click', ()=>{
        document.body.classList.remove('index-open');
      })
    })
    Array.from(document.querySelectorAll('.infos-opener')).forEach((el)=>{
      el.addEventListener('click', (e)=>{
        e.preventDefault();
        document.body.classList.add('infos-open');
      })
    })
    Array.from(document.querySelectorAll('.site-menu-infos-close')).forEach((el)=>{
      el.addEventListener('click', ()=>{
        document.body.classList.remove('infos-open');
      })
    })


    

    if(this.menuOpener){
      this.menuOpener.addEventListener('click', (e)=>{
        e.preventDefault()
        this.toggleMenu();
      })
    }
    
    
   
    document.querySelector('.main-navigation').addEventListener('keydown', (event) => {
      console.log("touch")
      if (event.which === this.keyCodes.escape) {
        if(document.body.classList.contains('menu-open')){
          this.toggleMenu();
        }
        
      }      
    });
    this.stickMenu(this.header)
    this.active = false;


    this.checkWinSize()
    window.addEventListener('resize', (e)=> {
      this.checkWinSize()

      
    });

	}

  reinitSplitType(){
   // console.log('reinit split type');
   
        
  }
  checkWinSize(){
    if(window.innerWidth >= 992 && this.active !== false && window.matchMedia("(hover: hover) and (pointer: fine)").matches){
      this.active = false;
      //this.killAccordion();
    }else if(this.active !== true && (window.innerWidth < 992 || !window.matchMedia("(hover: hover) and (pointer: fine)").matches)){
      
      this.active = true;
      //this.initAccordion();
    }
  }

 
 
	toggleMenu(){
    if(document.body.classList.contains('menu-open')){
       gsap.set(this.menu.querySelectorAll('.menu-item a>span'),{transitionDelay:  `0s`})
    }else{
      Array.from(this.menu.querySelectorAll('.menu-item a>span')).forEach((el, i)=>{
        gsap.set(el,{transitionDelay:  `${i*0.05+.2}s`})
     })
    }

    if(document.body.classList.contains('menu-open')){
      document.body.classList.remove('menu-open');
      this.setBlur(document.querySelector('#site-navigation'), this.menuOpener)
    }else if(!document.body.classList.contains('search-open')){
      document.body.classList.add('menu-open');
      this.setFocus(document.querySelector('#site-navigation'))
    }
    if(document.body.classList.contains('search-open')){
      document.body.classList.toggle('search-open');
    }
  }
  

  setFocus(dialog){
    const focusableElements = dialog.querySelectorAll(this.focusableElementsArray);
    const firstFocusableElement = focusableElements[0];
    const lastFocusableElement = focusableElements[focusableElements.length - 1];
    dialog.setAttribute('aria-hidden', false);
    this.mainContent.setAttribute('aria-hidden', true);

    // return if no focusable element
    if (!firstFocusableElement) {
      return;
    }

    window.setTimeout(() => {
      firstFocusableElement.focus();

      // trapping focus inside the dialog
      focusableElements.forEach((focusableElement) => {
        if (focusableElement.addEventListener) {
          focusableElement.addEventListener('keydown', (event) => {
            const tab = event.which === this.keyCodes.tab;

            if (!tab) {
              return;
            }

            if (event.shiftKey) {
              if (event.target === firstFocusableElement) { // shift + tab
                event.preventDefault();

                lastFocusableElement.focus();
              }
            } else if (event.target === lastFocusableElement) { // tab
              event.preventDefault();

              firstFocusableElement.focus();
            }
          });
        }
      });
    }, 100);
  }
  setBlur(dialog, trigger){
    dialog.setAttribute('aria-hidden', true);
    this.mainContent.setAttribute('aria-hidden', false);

    // restoring focus
    trigger.focus();
  }
  stickMenu(header){
    ScrollTrigger.create({
      id: "menuScrollTrigger",
      trigger: "#primary",
      start: 0,
      end: ()=> {
        return document.body.offsetHeight
      },
      refreshPriority: -2,
      invalidateOnRefresh: true,
      scrub: true,
      onEnter: function() { 
        if(!document.body.classList.contains('menu-open') || window.innerWidth > 767){
        }
        header.classList.add('sticky');  
      },
      onLeaveBack: function() { 
        header.classList.remove('hidden'); 
        header.classList.remove('sticky'); 
        header.classList.remove('reduce');   
      }
    });
    ScrollTrigger.create({
      trigger: header,
      start: 200,
      invalidateOnRefresh: true,
      onEnter : function() { header.classList.add('reduce') },
      onLeaveBack: function() { header.classList.remove('reduce') },
    })
  }
  
}