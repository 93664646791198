export const childrenMatches =  (elem, selector) => {
	return Array.prototype.filter.call(elem.children, function (child) {
		return child.matches(selector);
	});
};
export const loadScript = (source, beforeEl, async = true, defer = true) => {
  return new Promise((resolve, reject) => {
    let script = document.createElement('script');
    const prior = beforeEl || document.getElementsByTagName('script')[0];

    script.async = async;
    script.defer = defer;

    function onloadHander(_, isAbort) {
      if (isAbort || !script.readyState || /loaded|complete/.test(script.readyState)) {
        script.onload = null;
        script.onreadystatechange = null;
        script = undefined;

        if (isAbort) { reject(); } else { resolve(); }
      }
    }

    script.onload = onloadHander;
    script.onreadystatechange = onloadHander;

    script.src = source;
    prior.parentNode.insertBefore(script, prior);
  });
}
export const pad = function (d) {
  return (d < 10) ? '0' + d.toString() : d.toString();
}
export const parents = (node)=> {
  let current = node,
      list    = [];
  while(current.parentNode != null && current.parentNode != document.documentElement) {
    list.push(current.parentNode);
    current = current.parentNode;
  }
   return list
}
export const getParents = function(el, parentSelector) {
  if (parentSelector === undefined) {
        parentSelector = document;
    }
    var parents = [];
    var p = el.parentNode;
    while (p !== parentSelector) {
        var o = p;
        parents.push(o);
        p = o.parentNode;
    }
    parents.push(parentSelector);
    return parents;
}
export const index = function(el) {
  if (!el) return -1;
  var i = 0;
  do {
    i++;
  } while (el = el.previousElementSibling);
  return i;
}
export const slice = function(elements, start, end) {
  var sliced = Array.prototype.slice.call(elements, start, end);
  return sliced;
}
export const get = function(url, action ,nonce){
  return new Promise(function(resolve, reject){
    var xhr = new window.XMLHttpRequest();
    var wp_nonce = nonce ? nonce : "";
    var data = action ? action : "";
    
    
    if(data== ""){
      xhr.onreadystatechange = function(){
        if(xhr.readyState === 4){
          //console.log('terminé', xhr)
          if(xhr.status === 200){
            //console.log('terminé', xhr)
            //resolve(xhr.responseText)
            resolve(xhr);
            //return xhr;
          }else{
            reject(xhr);
          }
        }
      }
      xhr.open('GET', url, true);
      xhr.send();
    }else{
      xhr.open('POST', url, true);
      xhr.setRequestHeader('Content-Type', 'application/x-www-form-urlencoded;');
      xhr.onreadystatechange = function(){
        if(xhr.readyState === 4){
          //console.log('terminé', xhr)
          if(xhr.status === 200){
            //console.log('terminé', xhr)
            //resolve(xhr.responseText)
            resolve(xhr);
            //return xhr;
          }else{
            reject(xhr);
          }
        }
      }
      xhr.send(data);
      
    }
    
    
  })
  
}
export const postForm = function(form){
  return new Promise(function(resolve, reject){
    let actionPath = "";
    let formData = null;
    formData = new FormData(form);
    actionPath = form.getAttribute("action");
    var xhr = new window.XMLHttpRequest();
    xhr.onreadystatechange = function(){
      if(xhr.readyState === 4){
        //console.log('terminé', xhr)
        if(xhr.status === 200){
          //console.log('terminé', xhr)
          //resolve(xhr.responseText)
          resolve(xhr);
          //return xhr;
        }else{
          reject(xhr);
        }
      }
    }
    xhr.open('POST', actionPath);
    xhr.send(formData);
    
  })
  
}

export const htmlEntities = function(str) {
  return String(str).replace(/&/g, '&amp;').replace(/</g, '&lt;').replace(/>/g, '&gt;').replace(/"/g, '&quot;');
}
export const whichTransitionEvent = function() {
  let t
  const el = document.createElement('fakeelement')

  var transitions = {
    transition: 'transitionend',
    OTransition: 'oTransitionEnd',
    MozTransition: 'transitionend',
    WebkitTransition: 'webkitTransitionEnd'
  }

  for (t in transitions) {
    if (el.style[t] !== undefined) {
      return transitions[t]
    }
  }
}
///MULTIPLE PROMISES
/*const promises = []
    promises.push(get(this.restApiUrl + '/wp/v2/categories'))
    promises.push(get(this.restApiUrl + '/wp/v2/posts?per_page=' + this.perPage))
    Promise.all(promises)
    .then(promises => {
      
      //console.log(promises[1].getResponseHeader('X-WP-TotalPages'))
      //console.log(promises[1])
      //let cats = JSON.parse(promises[0]);
      //let posts = JSON.parse(promises[1]);JSON.parse
      //this.pageCallback(promises[0], promises[1])
      //promises.forEach(p => {
        //console.log(p)
      //})
    })*/
export const detectTouchEvent = function() {
  window.addEventListener('touchstart', function onFirstTouch() {
    // we could use a class
    document.body.classList.add('user-is-touching');
  
    // or set some global variable
    //window.USER_IS_TOUCHING = true;
  
    // or set your app's state however you normally would
    //myFrameworkOfChoice.dispatchEvent('USER_IS_TOUCHING', true);
  
    // we only need to know once that a human touched the screen, so we can stop listening now
    window.removeEventListener('touchstart', onFirstTouch, false);
  }, false);
}
export const countDecimals = function(value) {
  if (Math.floor(value) !== value)
      return value.toString().split(".")[1].length || 0;
  return 0;
}
export const  wrapInner = function(parent, wrapper, attribute, attributevalue) {
  if (typeof wrapper === "string") {
      wrapper = document.createElement(wrapper);

  }
  var div = parent.appendChild(wrapper)
            .setAttribute(attribute, attributevalue);

  while (parent.firstChild !== wrapper) {
         wrapper.appendChild(parent.firstChild);
    }
}
export const writeCookie = function (key, value, days) {
  var date = new Date();

  // Default at 365 days.
  days = days || 365;

  // Get unix milliseconds at current time plus number of days
  date.setTime(+ date + (days * 86400000)); //24 * 60 * 60 * 1000

  window.document.cookie = key + "=" + value + "; expires=" + date.toGMTString() + "; path=/";

  return value;
};
export const trapFocus = function(element) {
  var focusableEls = element.querySelectorAll('a[href]:not([disabled]), button:not([disabled]), textarea:not([disabled]), input[type="text"]:not([disabled]), input[type="radio"]:not([disabled]), input[type="checkbox"]:not([disabled]), select:not([disabled])');
  var firstFocusableEl = focusableEls[0];  
  var lastFocusableEl = focusableEls[focusableEls.length - 1];
  var KEYCODE_TAB = 9;

  element.addEventListener('keydown', function(e) {
    var isTabPressed = (e.key === 'Tab' || e.keyCode === KEYCODE_TAB);

    if (!isTabPressed) { 
      return; 
    }

    if ( e.shiftKey ) /* shift + tab */ {
      if (document.activeElement === firstFocusableEl) {
        lastFocusableEl.focus();
          e.preventDefault();
        }
      } else /* tab */ {
      if (document.activeElement === lastFocusableEl) {
        firstFocusableEl.focus();
          e.preventDefault();
        }
      }
  });
}