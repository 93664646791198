
//import { FloatLabel } from "./form.js";
//import { CheckBox } from "./form.js";
//import { RadioContactForm } from "./form.js";
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import {pad} from "./utils/shortcuts";
import { AjaxForm } from "./ajaxform.js";
import { formValidator } from "./form-validator.js"

const Pikaday = require('pikaday');
const { Dropzone } = require("./dropzone.min.js");
export class FloatLabel {
  constructor(inputs, inputGroup){
    this.inputGroup = inputGroup;
    this.inputs = document.querySelectorAll(inputs);
    this.changeInput = this.changeInput.bind(this);
    this.inputs.forEach(function(el, i, listObj){

      el.addEventListener('focus', this.changeInput)
      el.addEventListener('blur', this.changeInput)
    }, this);
    this.init = this.init.bind(this);
    this.init();
  }
  init(){
    document.querySelectorAll('input[type="checkbox"], input[type="radio"], select').forEach((input)=>{
      input.addEventListener('change', ()=>{
        ScrollTrigger.refresh();
      })
    })
    this.inputs.forEach(function(el, i, listObj){    
      if(el.value.length>0){
        el.closest(this.inputGroup).classList.add('floating-label');
      }
    }, this)
  }
  changeInput(e){
    //console.log(e.currentTarget.closest(this.inputGroup));
    console.log("float")
    if(e.type === "blur"){
      if(e.currentTarget.value.length<=0){
        e.currentTarget.closest(this.inputGroup).classList.remove('floating-label');
        //$(this).parent().parent().removeClass('floating-label');
        //$(this).parent().removeClass('floating-label');
      }
        
    }else{
      e.currentTarget.closest(this.inputGroup).classList.add('floating-label');
    }
  }
}
export class CheckBox {
  constructor(selector) {
    //console.log(selector);
    this.checkBox = document.querySelectorAll(selector);
    //console.log(this.checkBox)
    if(this.checkBox){
      this.checkBox.forEach(function(el, i, listObj){
        console.log(el)
        el.addEventListener('change', this.toggle);
      }, this)
    }
  }
  toggle(e){
    console.log('toggle')
    let label = e.currentTarget.closest('label');
    if(label){
      label.classList.toggle('checked');
    }
  }
}

export class RadioContactForm {
  constructor(){
    this.radioButtons = document.querySelectorAll('.popin-2 input[name="choice"]');
    this.toggle = this.toggle.bind(this);
    Array.from(this.radioButtons).forEach(function(el, i, listObj){
        el.addEventListener('change', this.toggle);
        //console.log(el)
    }, this)
  }
  toggle(e){
    console.log(`.form-${e.currentTarget.getAttribute("id")}`)
    Array.from(this.radioButtons).filter((el)=> el != e.currentTarget ).forEach(function(el, i, listObj){
      document.querySelector(`.form-${el.getAttribute("id")}`).classList.add("hidden");
    }, this)
    document.querySelector(`.form-${e.currentTarget.getAttribute("id")}`).classList.remove("hidden");
  }
}
export const formsInit = () => {
  const checkBox = new CheckBox('.wpcf7-post_checkbox input[type="checkbox"], .wpcf7-acceptance input[type="checkbox"], .mailpoet_checkbox_label input[type="checkbox"]');
  const radioContactForm = new RadioContactForm();
  // Array.from(document.querySelectorAll('.element.input')).forEach((el)=>{
  //   el.querySelector('input').setAttribute( 'placeholder', el.querySelector('label').innerText);
  //   el.classList.add('float')
  //   el.classList.add('field-item')
  // })
  if(document.querySelector('.field-item.float input')){
    const floatLabel = new FloatLabel('.field-item.float input', '.field-item');
    const floatLabel2 = new FloatLabel('.field-item.float textarea', '.field-item');

  }
  
  Array.from(document.querySelectorAll('.mailpoet-manage-subscription div.mailpoet_paragraph input[type="text"]')).forEach((el)=>{
    el.parentNode.classList.add('float');
    el.parentNode.classList.add('field-item');
    //console.log("label",el.parentNode.querySelector('label'));
    if(el.parentNode.querySelector('label')){
      const labelText = el.parentNode.querySelector('label').innerText;
      el.setAttribute("placeholder", labelText);
    }
    
  })
  const floatLabel3 = new FloatLabel('.mailpoet-manage-subscription div.mailpoet_paragraph input', 'div.mailpoet_paragraph');
  function getMonthName(dateStr, locale)
  {
      var date = new Date(dateStr);
      return date.toLocaleDateString(locale, { month: 'long' });  
      ///DOCUMENTATION :  https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Date/toLocaleDateString      
  }

  Array.from(document.querySelectorAll('.datepicker')).forEach((el)=>{
    const picker = new Pikaday({ 
      field: el,
      format: 'D/M/YYYY',
      toString(date, format) {
        // you should do formatting based on the passed format,
        // but we will just return 'D/M/YYYY' for simplicity
        const day = date.getDate();
        const month = date.getMonth() + 1;
        const year = date.getFullYear();
        const dateStr = `${pad(month)}-${pad(day)}-${year}`;

        const monthName = getMonthName(dateStr, document.documentElement.getAttribute('lang'));
        el.parentNode.querySelectorAll('.number').forEach((span)=>{
          span.innerHTML = day;
        });
        el.parentNode.querySelectorAll('.selected-date').forEach((span)=>{
          span.innerHTML = monthName;
        });
        el.parentNode.classList.add('date-choosen');
        // el.parentNode.querySelectorAll('.day').forEach((span)=>{
        //   span.style.display = 'none'
        // });
        //console.log(`${pad(day)} ${pad(monthName)} ${year}`, 'PARSEÒ');
        el.setAttribute('data-full-date', `${pad(day)} ${pad(monthName)} ${year}`);
        return `${pad(day)}-${pad(month)}-${year}`;
      },
      parse(dateString, format) {
          // dateString is the result of `toString` method
          const parts = dateString.split('/');
          const day = parseInt(parts[0], 10);
          const month = parseInt(parts[1], 10) - 1;
          const year = parseInt(parts[2], 10);
          return new Date(year, month, day);
      },
      minDate : new Date(),
      firstDay: 1,
      keyboardInput: false,
      i18n: {
        previousMonth : 'Mois préc.',
        nextMonth     : 'Mois suivant',
        months        : ['Janvier','Février','Mars','Avril','Mai','Juin','Juillet','Août','Septembre','Octobre','Novembre','Décembre'],
        weekdays      : ['Dimanche','Lundi','Mardi','Mercredi','Jeudi','Vendred','Samedi'],
        weekdaysShort : ['D','L','M','M','J','V','S']
      },
      onOpen: function(){
        el.classList.add('open')
      },
      onClose: function(){
        el.classList.remove('open')
        if(!this._d){
          el.checked = false;
        }
      },
      onSelect: function() {
        console.log('date selected' , el);
        if(document.querySelector(`.archive-filter-date-day input[value="${el.value}"]`)){
          document.querySelector(`.archive-filter-date-day input[value="${el.value}"]`).checked = true;
          el.checked = false;
          el.value = "";
          el.parentNode.querySelectorAll('.number').forEach((span)=>{
            span.innerHTML = '';
          });
          el.parentNode.querySelectorAll('.selected-date').forEach((span)=>{
            span.innerHTML = '';
          });
          el.parentNode.classList.remove('date-choosen');
          // el.parentNode.querySelectorAll('.day').forEach((span)=>{
          //   span.removeAttribute('style')
          // });
        }else{
          el.checked = true
        }
        
      }
    });
  })

  Array.from(document.querySelectorAll('.wpcf7-form')).forEach((el)=>{
    const select = el.querySelector('select[name="subject"]');
    if(select){
      select.querySelector(':first-child').disabled = true
    }
    


    // const conditional_group =  el.querySelector('.conditionnal-group');
    // if(select.value !== 'Retour concernant un produit'){
    //   conditional_group.style.display = 'none';
    // }else{
    //   conditional_group.removeAttribute('style');
    // }
    // ScrollTrigger.refresh();
    // select.addEventListener('change', (e)=>{
    //   if(e.currentTarget.value !== 'Retour concernant un produit'){
    //     conditional_group.style.display = 'none';
    //   }else{
    //     conditional_group.removeAttribute('style');
    //   }
    //   ScrollTrigger.refresh();
    // })
  })
  const ajaxForms = document.querySelectorAll('.wpcf7-form');
  let formsArray = [];
  let formsValid = [];

  ajaxForms.forEach((wpcf) => {
    formsValid.push(new formValidator(wpcf))
    console.log('FORM VALIDATOR')
  });

  ajaxForms.forEach((wpcf) => formsArray.push(new AjaxForm(wpcf)));

  const prooftagform = document.querySelectorAll('.form-prooftag');
  let formsPT = [];
  prooftagform.forEach((fpt) => formsPT.push(new ProofTagForm(fpt)));




  if(document.querySelector(".wpcf7-form #my-dropzone")){
    
    Dropzone.autoDiscover = false;
      let myDropzone = new Dropzone("#my-dropzone",{
     //Dropzone.options.myDropzone = {
      url: window.location.hostname === "localhost" ? "/graphiquants/upload.php" : "/upload.php",
      resizeWidth: 960,
      clickable: ".add-file",
      addRemoveLinks: true,
      //previewTemplate: previewTemplate,
      acceptedFiles: "image/jpeg,image/png,image/gif",
      maxFiles: 1,
      init: function() {
        this.on("sending", function(file, xhr, data) {
          console.log("file",file);
            if(file.fullPath){
              
                data.append("fullPath", file.fullPath);
  
            }
        });
        this.on("success", function(file, response) {
        //  console.log('sucess2', file.dataURL);
        //  console.log('response', response)
         document.querySelector('.wpcf7-form #file_name').setAttribute('value',file.name);
         //document.querySelector('.wpcf7-form #dataimg').setAttribute('value',file.dataURL);
        });
      }
     })
    
  }
}
