import { gsap } from "gsap";
import { ScrollToPlugin } from "gsap/ScrollToPlugin";
import {getParents} from "./utils/shortcuts.js";
import {postForm} from "./utils/shortcuts.js";
import {loadScript} from './utils/shortcuts';
// import { FloatLabel } from "./form.js";
// import { CheckBox } from "./form.js";
// import { formValidator } from "./form-validator.js"
import { formsInit } from './form';
gsap.registerPlugin(ScrollToPlugin);
var loadJSONP = (function(){
  var unique = 0;
  return function(url, callback, context) {
    // INIT

    var name = "_jsonp_" + unique++;
    if (url.match(/\?/)) url += "&callback="+name;
    else url += "?callback="+name;
    
    // Create script
    var script = document.createElement('script');
    script.type = 'text/javascript';
    script.src = url;
    
    // Setup handler
    window[name] = function(data){
      callback.call((context || window), data);
      document.getElementsByTagName('head')[0].removeChild(script);
      script = null;
      delete window[name];
    };
    
    // Load JSON
    document.getElementsByTagName('head')[0].appendChild(script);
  };
})();
export class AjaxForm {
  constructor(form) {
    console.log("form wpcf")
    this.form = form;
    this.onSubmit = this.onSubmit.bind(this)
    this.form.addEventListener("submit", this.onSubmit, this);
    this.progress = gsap.timeline({});
    
  }

  loadForm(form){
    const submitInput = form.querySelector('.wpcf7-submit');
    if(!submitInput){
      return
    }
    const submitLoader = document.createElement('div');
    submitLoader.classList.add('btn-submit-loader', 'btn');
    submitLoader.classList.add('wpcf7-submit', 'btn');
    submitLoader.classList.add('btn', 'btn');
    submitLoader.innerHTML = `<div class="progress"></div><span>${submitInput.getAttribute('value') || submitInput.innerHTML}</span>`;

    submitInput.insertAdjacentElement('beforebegin', submitLoader);
    
    this.progress.to('.btn-submit-loader .progress', {scaleX:.4, duration:.5})
    this.progress.to('.btn-submit-loader .progress', {scaleX:.7, duration:4, onComplete: ()=> this.progress.pause()})
    
    this.progress.addLabel('loaded');
    
    //this.progress.to('.btn-submit-loader .progress', {scaleX:1, duration:.5}, 'loaded');
  }
  onSubmit(e){
      e.preventDefault();
      this.loadForm(this.form)
      postForm(this.form).then((posts) => {
        const formId = this.form.parentNode.getAttribute("id");
        const div = document.createElement("div");
        const formContainer = this.form.parentNode
        div.innerHTML = posts.response; 
        
        const newForm = div.querySelector(`#${formId}`);document
        //console.log('form',newForm.offsetTop)
        gsap.to(window, {duration: 1, scrollTo: formContainer.getBoundingClientRect().top + window.scrollY - (document.querySelector('.site-header').offsetHeight)});
        gsap.to('.btn-submit-loader .progress', {scaleX:1, opacity:0, duration:1, onComplete: ()=> {
          formContainer.parentNode.appendChild(newForm);
          formContainer.parentNode.removeChild(formContainer);
          
          console.log(newForm.classList)
          
          
          // const checkBox = new CheckBox(`#${formId} .wpcf7-post_checkbox input[type="checkbox"]`);
          // if(document.querySelector(`#${formId} .field-item.float input`)){
          //   const floatLabel = new FloatLabel(`#${formId} .field-item.float input`, `#${formId} .field-item`);
          // }
          // const validationForm = new formValidator(newForm.querySelector('form'))
          // const ajaxForm = new AjaxForm(newForm.querySelector('form'))
          formsInit();
          const scriptUrl = 'https://www.google.com/recaptcha/api.js?onload=onRecaptchaLoad&render=explicit';
          loadScript(scriptUrl).then(() => {
            console.log('script loaded');
          }, () => {
            console.log('fail to load script');
          });
        }})

      })
        
        
          
      
      
      

  }
  serializeForm(form) {
    var obj = {};
    var formData = new FormData(form);
    for (var key of formData.keys()) {
      obj[key] = formData.get(key);
    }
    return obj;
  };
  jsonp(url) {
    return new Promise(function(resolve, reject) {
        let script = document.createElement('script')
        //const name = "_jsonp_" + Math.round(100000 * Math.random());
        const name = "_jsonp_1234";
        //url formatting
        if (url.match(/\?/)) url += "&callback="+name
        else url += "?callback="+name
        script.src = url;
        console.log(script)

        window[name] = function(data) {
            resolve(data);
            document.body.removeChild(script);
            delete window[name];
        }
        document.body.appendChild(script);
    });
  }
}

