import { gsap } from "gsap";

export const lineHoverEffect =  (container) => {
	Array.from(container.querySelectorAll('.lines-hover-effect')).forEach((el)=>{
   
    el.addEventListener('mouseenter', (e)=>{
      const lines = el.querySelectorAll(`${el.dataset.linesEffect} .line-inner`);
      const lineHover = gsap.timeline({
      });
      lineHover.set(lines,{'transition-delay':'0s', overwrite: true});
      lineHover.set(lines,{'transition':'none', overwrite: true});
      lineHover.set(lines, {y:()=>0, ease: "power2.out"})

      lineHover.to(lines, {yPercent:()=>-120, duration: .6, ease: "power2.in", stagger: .04})
      lineHover.set(lines, {yPercent:()=>120})
      lineHover.to(lines, {yPercent:()=>0, duration: .6, ease: "power2.out", stagger: .04})
    })
    el.addEventListener('mouseout', (e)=>{
      const lineOut = gsap.timeline({
      });
    })
  })
};